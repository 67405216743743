export class LocalStorageService {
  static SET_USER = (VALUE) => {
    const user = JSON.stringify(VALUE);
    localStorage.setItem("USER", user);
  };

  static GET_USER = () => {
    const user = localStorage.getItem("USER");
    if (user) {
      return JSON.parse(user);
    }
  };

  static SET_DATA = (KEY, VALUE) => {
    const data = JSON.stringify(VALUE);
    localStorage.setItem(KEY, data);
  };

  static GET_DATA = (KEY) => {
    if (typeof window !== "undefined") {
      const data = JSON.parse(localStorage.getItem(KEY));
      return data;
    }
    return null;
  };

  static LOGOUT = () => {
    localStorage.clear();
  };
}
