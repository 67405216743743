import React, { useEffect, useState } from "react";
import CustomHeader from "../../headers/CustomHeader";
import PageSubHeader from "../../PageSubHeader";
import CustomFooter from "../../CustomFooter";
import { Button, Spin } from "antd";
import { Image as Images } from "../../Images";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { ROUTES } from "../../../config/routes";
import { INCIDENT_DASHBOARD_BUTTONS } from "../../../config/static_data";
import {
  GetSpecificIncindet,
  UpdateIncidentStatus,
} from "../../../network/incident.network";
import { generateStatus, statusText } from "../../../config/config";
import GoogleMapReact from "google-map-react";
import { useLocation } from "../../../Provider/LocationProvider";

const IncidentDetails = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [incidentDetail, setIncidentDetails] = useState(null);

  const { locationCoordinates } = useLocation();

  const params = useParams();

  useEffect(() => {
    if (window !== "undefined") {
      window.scrollTo(0, 0);
    }
    getIncidentDetails();
  }, []);

  const getIncidentDetails = async () => {
    setLoader(true);
    const incidentResponse = await GetSpecificIncindet(params.id);
    setIncidentDetails(incidentResponse);
    setLoader(false);
  };

  const defaultProps = {
    center: {
      lat: 27.17,
      lng: 78.0081,
    },
    zoom: 11,
  };

  const handleActionClick = async (action) => {
    switch (action) {
      case "Responder Acceptance":
        await UpdateIncidentStatus(1, {
          incident_id: "TYA070",
          lat: locationCoordinates.lat,
          long: locationCoordinates.long,
        });
        break;

      default:
        break;
    }
  };
  return (
    <div>
      <div className="container-fluid main-inner-fluid mt-5">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 incident-btn-group">
              <ul className="list-inline d-flex align-items-center justify-content-center">
                {INCIDENT_DASHBOARD_BUTTONS.map((incidentItem, index) => {
                  return (
                    <li className="list-inline-item" key={index}>
                      <Button
                        onClick={() => handleActionClick(incidentItem.action)}
                        className="details-btn d-flex align-items-center shadow-none"
                      >
                        <img
                          src={incidentItem.icon}
                          alt=""
                          className="img-fluid"
                        />
                        {incidentItem.action}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 p-0 col-md-12 col-lg-8 col-xl-8">
              <div className="row card-common-row incident-card-details">
                <div className="col-12">
                  <h4 className="incident-detail-header position-relative">
                    Incident Detail
                  </h4>
                  {loader ? (
                    <div className="d-flex flex-column align-items-center mt-5">
                      <Spin size="large"></Spin>
                      <p className="mt-3">Fetching details</p>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="row mx-0 incident-inner-details">
                          <div className="col-12">
                            <h6 className="mb-0">Incident ID:</h6>
                            <h5 className="mb-0 mt-2">
                              #{incidentDetail?.incident_id}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {incidentDetail?.question_response &&
                        Object.entries(incidentDetail?.question_response).map(
                          (item) => {
                            return (
                              <div className="col-12 col-sm-12 col-md-6">
                                <div className="row mx-0 incident-inner-details">
                                  <div className="col-12">
                                    <h6 className="mb-0 hide-text">
                                      {item[0]}:
                                    </h6>
                                    <h5 className="mb-0 mt-2">{item[1]}</h5>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="row mx-0 incident-inner-details">
                          <div className="col-12">
                            <h6 className="mb-0 mt-2">Location:</h6>
                            <h5 className="primary-color mb-0 hide-text">
                              {incidentDetail?.location}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="row mx-0 incident-inner-details">
                          <div className="col-12">
                            <h6 className="mb-0">Contact Number:</h6>
                            <h5 className="mb-0 mt-2">
                              {incidentDetail?.phone_number}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6">
                        <div className="row mx-0 incident-inner-details">
                          <div className="col-12">
                            <h6 className="mb-0">Status:</h6>
                            <h5 className="mb-0 mt-2">
                              {statusText(incidentDetail?.status)}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="mt-5" style={{ height: "100vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBhONcn48RXONauIVIlEYuhd7zx0kdvrwg",
                }}
                defaultCenter={defaultProps.center}
                yesIWantToUseGoogleMapApiInternals={true}
                defaultZoom={defaultProps.zoom}
                onGoogleApiLoaded={({ map, maps }) => {
                  let marker = new maps.Marker({
                    position: defaultProps.center,
                    map,
                  });
                  return marker;
                }}
              ></GoogleMapReact>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <Button
                onClick={() => {
                  navigate(ROUTES.dashboard.incident_dashboard);
                }}
                className="go-back-dashboard-btn"
              >
                Go To Incident Dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentDetails;
