import React, { useCallback, useEffect, useState } from "react";
import CustomHeader from "../headers/CustomHeader";
import PageSubHeader from "../PageSubHeader";
import { Button, Dropdown, Select, Spin } from "antd";
import { Image as Images } from "../Images";
import CustomFooter from "../CustomFooter";
import { ROUTES } from "../../config/routes";
import { Link, useNavigate } from "react-router-dom";
import IncidentCard from "../UniversalComponent/IncidentCard";
import { GetIncident } from "../../network/incident.network";
import { fetchLocation } from "../../config/config";
import FilterIncident from "../FilterIncident";
import { reverse } from "named-urls";

const IncidentDashboard = () => {
  const [locationCoordinates, setLocationCoordinates] = useState({
    lat: -1,
    long: -1,
    locationAvailable: false,
  });
  const [incidentList, setIncidentList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterIncidentList, setFilterIncidentList] = useState([]);
  const [filter, setFilter] = useState({
    region: null,
    radius: null,
    status: null,
  });
  const [search, setSearch] = useState("");

  const getLocation = useCallback(
    () => fetchLocation(setLocationCoordinates),
    [locationCoordinates]
  );

  useEffect(() => {
    if (window !== "undefined") {
      window.scrollTo(0, 0);
    }
    if(locationCoordinates.lat===-1)
    getLocation();
  }, []);

  useEffect(() => {
    if (locationCoordinates.lat > 0) {
      setLoader(true);
      GetIncident(locationCoordinates)
        .then((incidentResponse) => {
          if (incidentResponse && incidentResponse.length > 0) {
            setIncidentList(incidentResponse);
            setFilterIncidentList(incidentResponse);
          }
        })
        .finally(() => setLoader(false));
    }
  }, [locationCoordinates]);

  const filterIncident = () => {
    if (!filter.region && !filter.radius && !filter.status) {
      setFilterIncidentList(incidentList);
    } else {
      const filterData = incidentList
        .map((item) => {
          if (filter.status) {
            if (item.status === filter.status) {
              return item;
            }
          }
          if (filter.radius) {
            if (Math.round(item.radius) <= Number(filter.radius)) {
              return item;
            }
          }
          if (filter.region) {
            if (item.region.includes(filter.status)) {
              return item;
            }
          }
        })
        .filter((notUndefined) => notUndefined !== undefined);
      setFilterIncidentList(filterData);
    }
  };

  const onSearch = () => {
    const filterData = incidentList
      .map((item) => {
        if (item.incident_id.toLowerCase().includes(search)) {
          return item;
        }
      })
      .filter((notUndefined) => notUndefined !== undefined);
    setFilterIncidentList(filterData);
  };

  useEffect(() => {
    filterIncident();
  }, [filter]);

  return (
    <>
      <PageSubHeader
        heading={"Incident Dashboard"}
        callback={setSearch}
        value={search}
        onSearch={onSearch}
      />
      <div className="container-fluid main-inner-fluid">
        <div className="container">
          <div className="row main-inner-row justify-content-between">
            <FilterIncident setFilter={setFilter} />
            <div className="details-card-right">
              {loader ||locationCoordinates.lat === -1 ? (
                <div className="d-flex mt-3 flex-column align-items-center">
                  <Spin
                    className="d-flex justify-content-center"
                    size="large"
                  />
                  {locationCoordinates.lat === -1 && (
                    <p className="mt-4">Fetching Location</p>
                  )}
                </div>
              ) : (
                <div className="row">
                  {filterIncidentList.length > 0 &&
                    filterIncidentList.map((item, index) => (
                      <div
                        key={index}
                        className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                      >
                        <Link to={reverse(ROUTES.dashboard.id, { id: item.id })}>
                          <IncidentCard incidentData={item} />
                        </Link>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncidentDashboard;
