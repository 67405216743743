import React from "react";
import { Image as Images } from "../Images";
import { Button, Collapse } from "antd";
import { ROUTES } from "../../config/routes";
import { Link } from "react-router-dom";

const { Panel } = Collapse;

const UserSidebar = (props) => {
  return (
    <div
      className={`position-fixed user-sidebar-custom ${
        props.clicked ? "profile-sidebar-active" : ""
      }`}
    >
      <Button
        onClick={props.handleClick}
        className="close-btn close-btn-profile shadow-none position-absolute bg-transparent p-0 h-auto border-0"
      >
        <img src={Images.cross_icon} alt="" className="img-fluid" />
      </Button>
      <div className="row position-relative">
        <div className="col-12">
          <div className="row user-profile-details align-items-center">
            <div className="w-auto user-img-div">
              <img src={Images.user_img} alt={""} className="img-fluid" />
            </div>
            <div className="w-auto user-details-div">
              <h5>Johan Smith</h5>
              <p className="mb-0">+91 98784 36548</p>
            </div>
          </div>
          <div className="row user-nav-details">
            <div className="col-12 p-0">
              <h6 className="text-uppercase">PERSONAL</h6>
              <ul className="list-inline">
                <li>
                  <Link to={ROUTES.auth.login}>User Profile</Link>
                </li>
                <li>
                  <Collapse accordion>
                    <Panel header="AED Device Details" key="1">
                      <ul className="list-inline mb-0">
                        <li>
                          <Link to={ROUTES.aed_device.self}>Show details</Link>
                        </li>
                        <li>
                          <Link to={ROUTES.aed_device.self}>Add details</Link>
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                </li>
                <li>
                  <Link to={ROUTES.dashboard.incident_assigned}>
                    Incidents Viewed
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.dashboard.incident_respondend}>
                    Incidents Responded to
                  </Link>
                </li>
                <li>
                  <Collapse accordion>
                    <Panel header="Certification" key="1">
                      <ul className="list-inline mb-0">
                        <li>
                          <Link to={ROUTES.certificates.self}>
                            Certification
                          </Link>
                        </li>
                        <li>
                          <Link to={ROUTES.auth.login}>Add Certification</Link>
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                </li>
                <li>
                  <Collapse accordion>
                    <Panel header="Training" key="1">
                      <ul className="list-inline mb-0">
                        <li>
                          <Link to={ROUTES.trainings.self}>Training</Link>
                        </li>
                        <li>
                          <Link to={ROUTES.auth.login}>Add Training</Link>
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                </li>
                <li>
                  <Link to={ROUTES.preferences.self}>Preferences</Link>
                </li>
                <li>
                  <Link to={ROUTES.support.self}>Support & Feedback</Link>
                </li>
                <li>
                  <Link to={ROUTES.contact.self}>Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSidebar;
