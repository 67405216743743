import React from "react";
import { Button, Input } from "antd";
import { Image as Images } from "./Images";

const { Search } = Input;

const PageSubHeader = ({
  callback = () => {},
  value = "",
  heading = "",
  onlySearchVisible,
  onSearch=()=>{}
}) => {
  return (
    <>
      <div className="container-fluid main-content-fluid">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center">
              <h5>{heading}</h5>
              <ul className="list-inline mb-0 d-flex align-items-center justify-content-center">
                <li className="list-inline-item custom-search-sub">
                  <Search
                    placeholder="Search"
                    value={value}
                    onChange={(e) => callback(e.target.value)}
                    onSearch={onSearch}
                  />
                </li>
                {!onlySearchVisible && (
                  <>
                    <li className="list-inline-item">
                      <Button className="location-map-btn bg-transparent border-0 p-0 shadow-none">
                        <img
                          src={Images.location_map_icon}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                    </li>
                    <li className="list-inline-item">
                      <Button className="incident-group-btn bg-transparent border-0 p-0 shadow-none">
                        <img
                          src={Images.incident_group_icon}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSubHeader;
