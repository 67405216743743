import { include } from "named-urls";

export const ENDPOINT = {
  user: include("user/api/v1/", {
    login: "login/",
    verify: "login-verify/",
    google_login:"google-login/"
  }),
  incident: include("incident/api/v1/", {
    get_incident: include("get-incident/",{
      self:"",
      id:":id/"
    }),
    incident:include("incident/",{
      self:"",
      id:":id/"

    }),
    accept_incident:"incident-accept/",
    update_status:"update-incident-status/"
  }),
};
