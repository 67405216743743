import { reverse } from "named-urls";
import { ENDPOINT } from "../config/endpoint";
import { API_GATEWAY } from "./api_service";
import { LocalStorageService } from "../config/LocalStorage";

export const Login = async (data) => {
  const payload = {
    phone: `${data.country_code}${data.phone_number}`,
    device_id: "aksdjland-asdjasdjl-asdjasndjasdasdlajsdnkjasndada+adas456",
  };
  const URL = reverse(ENDPOINT.user.login);
  try {
    const response = await API_GATEWAY.Post(URL, payload);
    return response;
  } catch (error) {}
};

export const SocialLogin = async (payload) => {
  const URL = reverse(ENDPOINT.user.google_login);
  try {
    const response = await API_GATEWAY.Post(URL, payload);
    LocalStorageService.SET_USER(response)
    return response;
  } catch (error) {}
};


export const VerifyOTP = async (payload) => {
  const URL = reverse(ENDPOINT.user.verify);
  try {
    const response = await API_GATEWAY.Post(URL, payload);
    LocalStorageService.SET_USER(response)
    return response;
  } catch (error) {}
};
