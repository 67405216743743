import React from "react";
import { Button, Tabs } from "antd";
import { Image as Images } from "../Images";
import UserSidebar from "../user-sidebar/UserSidebar";
import UserHeader from "../headers/UserHeader";

const Certificate = () => {
  return (
    <>
      <div className="main-user-content certificate-user-content">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-xl-11 px-0 position-relative">
            <>
              <Button className="add-certificate-btn d-flex align-items-center">
                <img src={Images.plus_btn_black} alt="" className="img-fluid" />
                Add Certificate
              </Button>
              <Tabs
                className="custom-tab-certificate"
                type="card"
                items={["Completed", "In-progress"].map((item, i) => {
                  const id = String(i + 1);
                  return {
                    label: item,
                    key: id,
                    children: (
                      <div className="certificate-div w-100">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                              <div className="d-flex incident-heading-inner position-relative">
                                <img
                                  src={Images.pptx_folder_icon}
                                  alt=""
                                  className="img-fluid"
                                />
                                <div className="training-details-div">
                                  <h6 style={{ top: 0 }} className="mb-0">
                                    CPR for Heart Patients
                                  </h6>
                                  <p className="mb-0">
                                    How to give CPR to Heart Patients
                                  </p>
                                  <img
                                    src={Images.rating_img}
                                    alt=""
                                    className="img-fluid rating-icon-img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                              <div className="d-flex incident-heading-inner position-relative">
                                <img
                                  src={Images.docs_folder_icon}
                                  alt=""
                                  className="img-fluid"
                                />
                                <div className="training-details-div">
                                  <h6 style={{ top: 0 }} className="mb-0">
                                    CPR for Pregnant women
                                  </h6>
                                  <p className="mb-0">
                                    How to give CPR to pregnant women
                                  </p>
                                  <img
                                    src={Images.rating_img}
                                    alt=""
                                    className="img-fluid rating-icon-img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                              <div className="d-flex incident-heading-inner position-relative">
                                <img
                                  src={Images.certified_folder_icon}
                                  alt=""
                                  className="img-fluid"
                                />
                                <div className="training-details-div">
                                  <h6 style={{ top: 0 }} className="mb-0">
                                    Certified CPR Responder
                                  </h6>
                                  <p className="mb-0">
                                    How to give CPR to pregnant women
                                  </p>
                                  <img
                                    src={Images.rating_img}
                                    alt=""
                                    className="img-fluid rating-icon-img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                              <div className="d-flex incident-heading-inner position-relative">
                                <img
                                  src={Images.pdf_folder_icon}
                                  alt=""
                                  className="img-fluid"
                                />
                                <div className="training-details-div">
                                  <h6 style={{ top: 0 }} className="mb-0">
                                    Certified CPR Responder
                                  </h6>
                                  <p className="mb-0">
                                    How to give CPR to pregnant women
                                  </p>
                                  <img
                                    src={Images.rating_img}
                                    alt=""
                                    className="img-fluid rating-icon-img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                              <div className="d-flex incident-heading-inner position-relative">
                                <img
                                  src={Images.video_folder_icon}
                                  alt=""
                                  className="img-fluid"
                                />
                                <div className="training-details-div">
                                  <h6 style={{ top: 0 }} className="mb-0">
                                    Certified CPR Responder
                                  </h6>
                                  <p className="mb-0">
                                    How to give CPR to pregnant women
                                  </p>
                                  <img
                                    src={Images.rating_img}
                                    alt=""
                                    className="img-fluid rating-icon-img"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  };
                })}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certificate;
