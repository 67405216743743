import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/custom.css";
import "../src/assets/css/responsive.css";
import IncidentDashboard from "./components/incident-dashboard/IncidentDashboard";
import IncidentDetails from "./components/incident-dashboard/details/IncidentDetails";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ROUTES } from "./config/routes";
import { useEffect } from "react";
import CustomHeader from "./components/headers/CustomHeader";
import CustomFooter from "./components/CustomFooter";
import Trainings from "./components/training/Trainings";
import SelectTraining from "./components/training/SelectTraining";
import Support from "./components/support/Support";
import UserProfile from "./components/user-profile/UserProfile";
import AedDetails from "./components/aed-detail/AedDetails";
import AssinedIncidents from "./components/assined-incidents/AssinedIncidents";
import UserSidebar from "./components/user-sidebar/UserSidebar";
import UserHeader from "./components/headers/UserHeader";
import Certificate from "./components/certificate/Certificate";
import ContactUs from "./components/contact-us/ContactUs";
import Preferences from "./components/preferences/Preferences";
import TrainingProfile from "./components/training-profile/TrainingProfile";
import LoginScreen from "./page/Authentication/Login";
import VerificationCode from "./page/Authentication/VerificationCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./page/Authentication/ProtectedRoute";
import SplashScreen from "./page/Authentication/SplashScreen";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LocationProvider from "./Provider/LocationProvider";

function App() {
  return (
    <div className="App">
      <ToastContainer newestOnTop limit={1} />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <LocationProvider>
          <BrowserRouter>
            <Routes>
              <Route path={ROUTES.self} element={<SplashScreen />} />
              <Route path={ROUTES.auth.login} element={<LoginScreen />} />
              <Route
                path={ROUTES.auth.otp_verification}
                element={<VerificationCode />}
              />
              <Route element={<ProtectedRoute />}>
                <Route element={<MainLayout />}>
                  <Route
                    path={ROUTES.dashboard.incident_dashboard}
                    element={<IncidentDashboard />}
                  />
                  <Route
                    element={<IncidentDetails />}
                    path={ROUTES.dashboard.id}
                  />
                  <Route
                    path={ROUTES.trainings.self}
                    element={<SelectTraining />}
                  />
                  <Route
                    path={ROUTES.trainings.dashboard}
                    element={<Trainings />}
                  />
                  <Route path={ROUTES.support.self} element={<Support />} />
                  <Route
                    path={ROUTES.aed_device.self}
                    element={<AedDetails />}
                  />
                  <Route path={ROUTES.contact.self} element={<ContactUs />} />
                </Route>

                <Route element={<UserLayout />}>
                  <Route
                    path={ROUTES.dashboard.incident_assigned}
                    element={<AssinedIncidents />}
                  />
                  <Route
                    path={ROUTES.dashboard.incident_respondend}
                    element={<AssinedIncidents />}
                  />
                  <Route
                    path={ROUTES.certificates.self}
                    element={<Certificate />}
                  />
                  <Route
                    path={ROUTES.preferences.self}
                    element={<Preferences />}
                  />
                </Route>

                <Route path={ROUTES.profile.self} element={<UserProfile />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </LocationProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;

const MainLayout = (props) => {
  return (
    <div>
      <CustomHeader />
      <Outlet />
      <CustomFooter />
    </div>
  );
};

const UserLayout = (props) => {
  return (
    <div>
      <UserSidebar />
      <UserHeader />
      <Outlet />
    </div>
  );
};
