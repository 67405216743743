import React, { useState } from "react";
import { Button, Dropdown } from "antd";
import { Image as Images } from "../Images";
import UserSidebar from "../user-sidebar/UserSidebar";
import { useLocation } from "react-router-dom";

const UserHeader = (props) => {
  const [clicked, setClicked] = useState("");
  const handleClick = () => {
    clicked ? setClicked("") : setClicked("base-state click-state");
  };
  const params = useLocation();
  const data = params.pathname.split("/");
  const headerString = data
    .filter((item) => item.trim() !== "") // Filter out empty strings and spaces
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1)) // Capitalize first letter
    .join(" ");
  return (
    <>
      <UserSidebar clicked={clicked} handleClick={handleClick} />
      <div className="header-main position-fixed user-header-custom">
        <div className="container h-100">
          <div className="row h-100 align-items-center position-relative justify-content-between">
            <div className="w-auto d-flex align-items-center">
              <Button onClick={handleClick} className="toggle-btn-mobile">
                <img
                  src={Images.toggle_btn_icon}
                  alt=""
                  className="img-fluid"
                />
              </Button>
              <h5 className="mb-0 text-uppercase">{headerString}</h5>
            </div>
            <div className="header-dropdown-custom w-auto">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <Button className="w-auto h-auto p-0 border-0 shadow-none bg-transparent rounded-0">
                    <img
                      src={Images.message_icon}
                      alt=""
                      className="img-fluid"
                    />
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button className="w-auto h-auto p-0 border-0 shadow-none bg-transparent rounded-0">
                    <img
                      src={Images.notification_icon}
                      alt=""
                      className="img-fluid"
                    />
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className="custom-dropdown p-0 border-0 shadow-none rounded-0 bg-transparent"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img src={Images.avatar} alt="" className="img-fluid" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
