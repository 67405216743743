import React from "react";
import CustomHeader from "../headers/CustomHeader";
import CustomFooter from "../CustomFooter";
import BannerMain from "../BannerMain";
import { Button, Form, Input } from "antd";

const { TextArea } = Input;

const ContactUs = () => {
  return (
    <>
      <CustomHeader />
      <BannerMain headingName={"Contact Us"} />
      <div className="main-inner-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 mx-auto">
              <Form
                className="common-form-main"
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                // initialValues={{
                //     remember: true,
                // }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <div className="row gallery-img-row-card">
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      label={false}
                      name="name"
                      rules={[
                        {
                          required: false,
                          message: "Please Input Your name!",
                        },
                      ]}
                    >
                      <Input placeholder="Your name" />
                    </Form.Item>
                    <Form.Item
                      label={false}
                      name="mobile_no"
                      rules={[
                        {
                          required: false,
                          message: "Please Input Your mobile no!",
                        },
                      ]}
                    >
                      <Input placeholder="Your mobile no" />
                    </Form.Item>
                    <Form.Item
                      label={false}
                      name="email"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Your email address!",
                        },
                      ]}
                    >
                      <Input placeholder="Your email address" />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <Form.Item
                      label={false}
                      name="Message"
                      rules={[
                        {
                          required: false,
                          message: "Please Input Message!",
                        },
                      ]}
                    >
                      <TextArea
                        className="textArea-custom-contact"
                        placeholder="Message"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <Button className="go-back-dashboard-btn">Submit</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
