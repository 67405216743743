import toggle_btn_icon from "../assets/images/icons/toggle-btn-icon.png";
import back_arrow_icon from "../assets/images/icons/back-arrow-black.png";
import cpr_logo from "../assets/images/cpr-logo.svg";
import avatar from "../assets/images/avatar.svg";
import location_map_icon from "../assets/images/icons/loaction-map-icon.svg";
import incident_group_icon from "../assets/images/icons/incident-group-icon.svg";
import sorter_icon from "../assets/images/icons/sorter-icon.svg";
import heart_icon_primary from "../assets/images/icons/heart-icon-primary.png";
import incident_car_icon from "../assets/images/icons/incident-car-icon.png";
import elipsis_icon_gray from "../assets/images/icons/elipsis-icon-gray.svg";
import awaiting_responder_icon from "../assets/images/icons/awaiting-responder-icon.png";
import insident_responded_icon from "../assets/images/icons/insident-responded-icon.png";
import insta_group from "../assets/images/icons/insta-group.svg";
import incident_location from "../assets/images/icons/incident-location.svg";
import incident_location_icon_primary from "../assets/images/icons/incident-location-icon-primary.svg";
import responder_acceptance from "../assets/images/icons/responder-acceptance.svg";
import navigation from "../assets/images/icons/navigation.svg";
import responder_details from "../assets/images/icons/responder-details.svg";
import responder_attending_patient from "../assets/images/icons/responder-attending-patient.svg";
import incident_closed from "../assets/images/icons/incident_closed.svg";
import navigation_primary from "../assets/images/icons/navigation-primary.svg";
import map_img from "../assets/images/map-img.png";
import logo_auths from "../assets/images/logo-auths.svg";
import apple_logo from "../assets/images/apple-logo.svg";
import facebook_logo from "../assets/images/facebook-logo.svg";
import google_logo from "../assets/images/google-logo.svg";
import training_book_icon from "../assets/images/icons/training-book-icon.svg";
import pptx_folder_icon from "../assets/images/icons/pptx-folder-icon.svg";
import docs_folder_icon from "../assets/images/icons/docs-folder-icon.svg";
import rating_img from "../assets/images/rating-img.svg";
import heart_icon_gray from "../assets/images/icons/heart-icon-gray.svg";
import certified_folder_icon from "../assets/images/icons/certified-folder-icon.svg";
import pdf_folder_icon from "../assets/images/icons/pdf-folder-icon.svg";
import video_folder_icon from "../assets/images/icons/video-folder-icon.svg";
import cross_icon from "../assets/images/icons/cross-icon.svg";
import report_an_incident_icon from "../assets/images/sidebar/report-an-incident-icon.png";
import incident_dashboard_icon from "../assets/images/sidebar/incident-dashboard-icon.png";
import training_sidebar_icon from "../assets/images/sidebar/training-sidebar-icon.svg";
import im_a_responder_icon from "../assets/images/sidebar/i-m-a-responder-icon.svg";
import support_icon from "../assets/images/sidebar/support-icon.svg";
import user_icon_primary from "../assets/images/icons/user-icon-primary.svg";
import message_icon from "../assets/images/icons/message-icon.svg";
import notification_icon from "../assets/images/icons/notification-icon.svg";
import user_img from "../assets/images/user-img.svg";
import caret_icon_down from "../assets/images/icons/caret-icon-down.svg";
import calendar_icon_primary from "../assets/images/icons/calendar-icon-primary.svg";
import clock_icon from "../assets/images/icons/clock-icon.svg";
import ellipse_single from "../assets/images/icons/ellipse-single.svg";
import upload_icon from "../assets/images/icons/upload-icon.svg";
import plus_icon from "../assets/images/icons/plus-icon.svg";
import minus_icon from "../assets/images/icons/minus-icon.svg";
import plus_btn_black from "../assets/images/icons/plus-btn-black.svg";
import location from "../assets/images/location.gif";
import error_location from "../assets/images/error-location.gif"

export let Image = {
  toggle_btn_icon,
  back_arrow_icon,
  cpr_logo,
  avatar,
  location_map_icon,
  incident_group_icon,
  sorter_icon,
  heart_icon_primary,
  incident_car_icon,
  elipsis_icon_gray,
  awaiting_responder_icon,
  insident_responded_icon,
  insta_group,
  incident_location_icon_primary,
  responder_acceptance,
  navigation,
  responder_details,
  apple_logo,
  responder_attending_patient,
  incident_closed,
  incident_location,
  navigation_primary,
  map_img,
  logo_auths,
  facebook_logo,
  google_logo,
  training_book_icon,
  pptx_folder_icon,
  rating_img,
  heart_icon_gray,
  docs_folder_icon,
  certified_folder_icon,
  pdf_folder_icon,
  video_folder_icon,
  cross_icon,
  report_an_incident_icon,
  incident_dashboard_icon,
  training_sidebar_icon,
  im_a_responder_icon,
  support_icon,
  user_icon_primary,
  notification_icon,
  user_img,
  caret_icon_down,
  message_icon,
  calendar_icon_primary,
  clock_icon,
  ellipse_single,
  upload_icon,
  plus_icon,
  minus_icon,
  plus_btn_black,
  location,
  error_location
};
