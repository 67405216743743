import React from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import {LocalStorageService} from "../../config/LocalStorage"
import { ROUTES } from "../../config/routes";

const ProtectedRoute = (props) => {
  const location = useLocation()
  return (
    <div>
      {LocalStorageService.GET_USER() ? (
        <>
          <Outlet />
        </>
      ) : (
        <Navigate to={ROUTES.auth.login} />
      )}
    </div>
  );
};

export default ProtectedRoute;
