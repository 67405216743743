import React from "react";
import { Image as Images } from "./Images";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { ROUTES } from "../config/routes";

const CustomFooter = () => {
  return (
    <>
      <div className="container-fluid custom-footer-fluid position-relative">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <Link
                to={ROUTES.auth.login}
                className="d-flex align-items-center logo-footer justify-content-center"
              >
                <img src={Images.cpr_logo} alt="" className="img-fluid" />
                CPR Ready
              </Link>
              <ul className="list-inline footer-nav-links">
                <li className="list-inline-item">
                  <Link to={ROUTES.auth.login}>Home</Link>
                </li>
                <li className="list-inline-item">
                  <Link href={"/about_us"}>About Us</Link>
                </li>
                <li className="list-inline-item">
                  <Link href={"/service"}>Service</Link>
                </li>
                <li className="list-inline-item">
                  <Link href={"/inquiry"}>Inquiry</Link>
                </li>
                <li className="list-inline-item">
                  <Link href={"/contact_us"}>Contact Us</Link>
                </li>
              </ul>
              <Button className="btn border-0 h-auto p-0 shadow-none bg-transparent">
                <img src={Images.insta_group} alt="" className="img-fluid" />
              </Button>
            </div>
          </div>
        </div>
        <div className="footer-bottom-card position-absolute w-100 d-flex align-items-center justify-content-center">
          @lorem ipsum dolor, sit amet consectetu
        </div>
      </div>
    </>
  );
};

export default CustomFooter;
