import React, { useCallback, useEffect, useState } from "react";
import { fetchLocation } from "../../config/config";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import { Image } from "../../components/Images";
import { useLocation } from "../../Provider/LocationProvider";

const SplashScreen = () => {

  const { getLocation,locationCoordinates} = useLocation()
  const nav = useNavigate()
  useEffect(() => {
    if (locationCoordinates.lat !== -1 && locationCoordinates.lat !== 0) {
      nav(ROUTES.auth.login);
    } else {
      getLocation();
    }
  }, [locationCoordinates]);

  return (
    <div className="flex-column d-flex justify-content-center align-items-center">
      {locationCoordinates.lat === -1 ? (
        <div className="mt-5 flex-column d-flex justify-content-center align-items-center">
          <img src={Image.location} alt=""></img>
          <p className="mt-3">Please Wait CPR Ready is Fetching Location</p>
        </div>
      ) : (
        locationCoordinates.lat === 0 &&<div className="mt-5 flex-column d-flex justify-content-center align-items-center">
        <img src={Image.error_location} alt=""></img>
        <p className="mt-3">Error Fetching Location</p>
      </div>
        
        
      )}
    </div>
  );
};

export default SplashScreen;
