import { Bounce } from "react-toastify";
import { Image as Images } from "../components/Images";

export const toastConfig = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export const fetchLocation = (callback) => {
  if (navigator.geolocation) {
    // Call getCurrentPosition with success and failure callbacks
    navigator.geolocation.getCurrentPosition(
      (data) => {
        callback({
          lat: data.coords.latitude,
          long: data.coords.longitude,
          locationAvailable: true,
        });
      },
      (error) => {
        callback({
          lat: 0,
          long: 0,
          locationAvailable: false,
        });
      }
    );
  } else {
    alert("Sorry, your browser does not support geolocation services.");
  }
};

export const generateStatus = (statusValue) => {
  switch (statusValue) {
    case "Awaiting Responder Acceptence":
      return (
        <img
          src={Images.awaiting_responder_icon}
          height={30}
          width={30}
          alt=""
          className="img-fluid"
        />
      );
    case "Responder in-transit":
      return (
        <img
          src={Images.incident_car_icon}
          height={30}
          width={30}
          alt=""
          className="img-fluid"
        />
      );
    case "Responder attending patient":
      return (
        <img
          src={Images.heart_icon_primary}
          height={30}
          width={30}
          alt=""
          className="img-fluid"
        />
      );
  }
};

export const statusText = (status) => {
  switch (status) {
    case "Awaiting Responder Acceptence":
      return <p className="mb-0 mx-2 orange"> {status}</p>;
    case "Responder in-transit":
      return <p className="mb-0 mx-2 blue"> {status}</p>;
    case "Responder attending patient":
      return <p className="mb-0 mx-2 green"> {status}</p>;
  }
};
