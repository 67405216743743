import { include } from "named-urls";

export const ROUTES = {
  self: "/",
  auth: include("/auth/", {
    login: "login",
    otp_verification: "otp-verification",
  }),
  dashboard: include("/incident/", {
    incident_dashboard: "dashboard/",
    id: ":id/",
    incident_assigned: "assigned/",
    incident_respondend: "respondend/",
  }),
  trainings: include("/trainings/", {
    self: "",
    dashboard: "dashboard/",
  }),
  support: include("/support/", {
    self: "",
  }),
  profile: include("/profile/", {
    self: ":id/",
  }),
  aed_device: include("/aed_device/", {
    self: "",
    add: "add/",
  }),
  certificates: include("/certificates/", {
    self: "",
    add: "add/",
  }),
  contact: include("/contact/", {
    self: "",
  }),
  preferences: include("/preferences/", {
    self: "",
  }),
};
