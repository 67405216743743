import { createContext, useCallback, useContext, useState } from "react";
import { fetchLocation } from "../config/config";

const LocationContext = createContext({
  locationCoordinates: {
    lat: -1,
    long: -1,
    locationAvailable: false,
  },
  getLocation: () => {},
});

export const useLocation = () => useContext(LocationContext);

const LocationProvider = (props) => {
  const [locationCoordinates, setLocationCoordinates] = useState({
    lat: -1,
    long: -1,
    locationAvailable: false,
  });

  const getLocation = useCallback(
    () => fetchLocation(setLocationCoordinates),
    [locationCoordinates]
  );

  return (
    <LocationContext.Provider
      value={{
        locationCoordinates,
        getLocation,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
