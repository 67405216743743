import { Select } from "antd";
import { Image as Images } from "../components/Images";
import React from "react";

const FilterIncident = ({ setFilter }) => {
  const region = [
    {
      value: "detroit",
      label: "Detroit",
    },
    {
      value: "kansas",
      label: "Kansas",
    },
    {
      value: "houston",
      label: "Houston",
    },
  ];
  const radius = [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "10",
      label: "10",
    },
  ];

  const status = [
    {
      value: "Awaiting Responder Acceptence",
      label: "Awaiting Responder Acceptence",
    },
    {
      value: "Responder in-transit",
      label: "Responder in-transit",
    },
    {
      value: "Responder attending patient",
      label: "Responder attending patient",
    },
  ];

  return (
    <div className="filter-card-left">
      <div className="row mx-0 card-common-row">
        <div className="col-12">
          <h5>Filter by</h5>
          <Select
            suffixIcon={
              <img src={Images.sorter_icon} alt="" className="img-fluid" />
            }
            placeholder="Region"
            options={region}
            onChange={(e) =>
              setFilter((prevState) => ({ ...prevState, region: e }))
            }
            allowClear
            onClear={() =>
              setFilter((prevState) => ({ ...prevState, region: null }))
            }
          />
          <Select
            suffixIcon={
              <img src={Images.sorter_icon} alt="" className="img-fluid" />
            }
            placeholder="Radius (miles)"
            options={radius}
            onChange={(e) =>
              setFilter((prevState) => ({ ...prevState, radius: e }))
            }
            allowClear
            onClear={() =>
              setFilter((prevState) => ({ ...prevState, radius: null }))
            }
          />
          <Select
            suffixIcon={
              <img src={Images.sorter_icon} alt="" className="img-fluid" />
            }
            placeholder="Status"
            options={status}
            onChange={(e) =>
              setFilter((prevState) => ({ ...prevState, status: e }))
            }
            allowClear
            onClear={() =>
              setFilter((prevState) => ({ ...prevState, status: null }))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FilterIncident;
