import React, { useState } from "react";
import { Image as Images } from "../../components/Images";
import { Button, Form, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { Login, SocialLogin } from "../../network/authentication.network";
import { ROUTES } from "../../config/routes";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import { toastConfig } from "../../config/config";
import axios from "axios";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [socialLoader, setSocialLoader] = useState(false);
  const userLogin = async (data = {}, loginType = "phone_number") => {
    if (loginType === "phone_number") {
      setLoader(true);
      const response = await Login(data);
      if (response) {
        navigate(ROUTES.auth.otp_verification, {
          state: {
            phone_number: data.phone_number,
            secret_code: response.token,
          },
        });
      }
      setLoader(false);
    }
  };


  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setSocialLoader(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          const payload = {
            device_id:
              "aksdjland-asdjasdjl-asdjasndjasdasdlajsdnkjasndada+adas456",
            token: tokenResponse.access_token,
            name: res.data.name,
            email: res.data.email,
          };

          SocialLogin(payload)
            .then((response) => {
              toast.success("Logged In Successfully", toastConfig);
              navigate(ROUTES.dashboard.incident_dashboard);
            })
            .finally(() => setSocialLoader(false));
        })
        .catch((err) => console.log(err))
        .finally(() => setSocialLoader(false));
    },
    select_account: true,
  });


  return (
    <>
      <div className="container-fluid main-inner-fluid auths-details-main">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div className="auths-main-card">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    src={Images.logo_auths}
                    alt=""
                    className="img-fluid auths-logo"
                  />
                  <h1>Mobile Verification</h1>
                  <p>Please enter your mobile number to verify your account</p>
                  <Form autoComplete="off" onFinish={userLogin}>
                    <div className="d-flex w-100 justify-content-around">
                      <Form.Item name="country_code" initialValue={"+91"}>
                        <Select
                          defaultValue="+91"
                          className="select-country"
                          allowClear={false}
                          options={[
                            {
                              value: "+91",
                              label: "+91",
                            },
                            {
                              value: "+1",
                              label: "+1",
                            },
                          ]}
                        />
                      </Form.Item>

                      <Form.Item
                        name="phone_number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Phone Number!",
                          },
                        ]}
                      >
                        <Input placeholder="Mobile Number" maxLength={10} />
                      </Form.Item>
                    </div>

                    <Form.Item className="mb-0">
                      <Button
                        loading={loader}
                        type="primary"
                        htmlType="submit"
                        className="text-uppercase"
                        disabled={socialLoader}
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                  <div className="row">
                    <div className="col-12 continue-with-div text-center position-relative">
                      <h6>
                        <span>or continue with</span>
                      </h6>
                      <ul className="list-inline mb-0">
                        {/* <li className="list-inline-item">
                          <Button>
                            <img
                              src={Images.apple_logo}
                              alt=""
                              className="img-fluid"
                            />
                          </Button>
                        </li>
                        <li className="list-inline-item">
                          <Button>
                            <img
                              src={Images.facebook_logo}
                              alt=""
                              className="img-fluid"
                            />
                          </Button>
                        </li> */}
                        <li className="list-inline-item">
                          <Button onClick={login}>
                            <img
                              src={Images.google_logo}
                              alt=""
                              className="img-fluid"
                            />
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
