import React from "react";
import CustomHeader from "../headers/CustomHeader";
import PageSubHeader from "../PageSubHeader";
import { Button, Dropdown, Select } from "antd";
import { Image as Images } from "../Images";
import CustomFooter from "../CustomFooter";

const items = [
  {
    label: <a href="https://www.antgroup.com">Edit</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">Delete</a>,
    key: "1",
  },
];
const Trainings = () => {
  return (
    <>
      <PageSubHeader heading={"Trainings"} onlySearchVisible={true} />
      <div className="container-fluid main-inner-fluid">
        <div className="container">
          <div className="row main-inner-row justify-content-between">
            <div className="filter-card-left">
              <div className="row mx-0 card-common-row">
                <div className="col-12">
                  <h5>Filter by</h5>
                  <Select
                    suffixIcon={
                      <img
                        src={Images.sorter_icon}
                        alt=""
                        className="img-fluid"
                      />
                    }
                    defaultValue="Filter by Content Type"
                    options={[
                      {
                        value: "Filter by Content Type",
                        label: "Filter by Content Type",
                      },
                    ]}
                  />
                  <Select
                    suffixIcon={
                      <img
                        src={Images.sorter_icon}
                        alt=""
                        className="img-fluid"
                      />
                    }
                    defaultValue="Filter by Age Group"
                    options={[
                      {
                        value: "Filter by Age Group",
                        label: "Filter by Age Group",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="details-card-right">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                    <div className="d-flex incident-heading-inner position-relative">
                      <Button
                        className="more-info-btn bg-transparent p-0 border-0 shadow-none rounded-0 h-auto"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          style={{ width: "auto", height: "auto" }}
                          src={Images.heart_icon_gray}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                      <img
                        src={Images.pptx_folder_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="training-details-div">
                        <h6 style={{ top: 0 }} className="mb-0">
                          CPR for Heart Patients
                        </h6>
                        <p className="mb-0">
                          How to give CPR to Heart Patients
                        </p>
                        <img
                          src={Images.rating_img}
                          alt=""
                          className="img-fluid rating-icon-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                    <div className="d-flex incident-heading-inner position-relative">
                      <Button
                        className="more-info-btn bg-transparent p-0 border-0 shadow-none rounded-0 h-auto"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          style={{ width: "auto", height: "auto" }}
                          src={Images.heart_icon_gray}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                      <img
                        src={Images.docs_folder_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="training-details-div">
                        <h6 style={{ top: 0 }} className="mb-0">
                          CPR for Pregnant women
                        </h6>
                        <p className="mb-0">
                          How to give CPR to pregnant women
                        </p>
                        <img
                          src={Images.rating_img}
                          alt=""
                          className="img-fluid rating-icon-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                    <div className="d-flex incident-heading-inner position-relative">
                      <Button
                        className="more-info-btn bg-transparent p-0 border-0 shadow-none rounded-0 h-auto"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          style={{ width: "auto", height: "auto" }}
                          src={Images.heart_icon_gray}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                      <img
                        src={Images.certified_folder_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="training-details-div">
                        <h6 style={{ top: 0 }} className="mb-0">
                          Certified CPR Responder
                        </h6>
                        <p className="mb-0">
                          How to give CPR to pregnant women
                        </p>
                        <img
                          src={Images.rating_img}
                          alt=""
                          className="img-fluid rating-icon-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                    <div className="d-flex incident-heading-inner position-relative">
                      <Button
                        className="more-info-btn bg-transparent p-0 border-0 shadow-none rounded-0 h-auto"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          style={{ width: "auto", height: "auto" }}
                          src={Images.heart_icon_gray}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                      <img
                        src={Images.pdf_folder_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="training-details-div">
                        <h6 style={{ top: 0 }} className="mb-0">
                          Certified CPR Responder
                        </h6>
                        <p className="mb-0">
                          How to give CPR to pregnant women
                        </p>
                        <img
                          src={Images.rating_img}
                          alt=""
                          className="img-fluid rating-icon-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                    <div className="d-flex incident-heading-inner position-relative">
                      <Button
                        className="more-info-btn bg-transparent p-0 border-0 shadow-none rounded-0 h-auto"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          style={{ width: "auto", height: "auto" }}
                          src={Images.heart_icon_gray}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                      <img
                        src={Images.video_folder_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="training-details-div">
                        <h6 style={{ top: 0 }} className="mb-0">
                          Certified CPR Responder
                        </h6>
                        <p className="mb-0">
                          How to give CPR to pregnant women
                        </p>
                        <img
                          src={Images.rating_img}
                          alt=""
                          className="img-fluid rating-icon-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                    <div className="d-flex incident-heading-inner position-relative">
                      <Button
                        className="more-info-btn bg-transparent p-0 border-0 shadow-none rounded-0 h-auto"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          style={{ width: "auto", height: "auto" }}
                          src={Images.heart_icon_gray}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                      <img
                        src={Images.pptx_folder_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="training-details-div">
                        <h6 style={{ top: 0 }} className="mb-0">
                          Certified CPR Responder
                        </h6>
                        <p className="mb-0">
                          How to give CPR to pregnant women
                        </p>
                        <img
                          src={Images.rating_img}
                          alt=""
                          className="img-fluid rating-icon-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                    <div className="d-flex incident-heading-inner position-relative">
                      <Button
                        className="more-info-btn bg-transparent p-0 border-0 shadow-none rounded-0 h-auto"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          style={{ width: "auto", height: "auto" }}
                          src={Images.heart_icon_gray}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                      <img
                        src={Images.pptx_folder_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="training-details-div">
                        <h6 style={{ top: 0 }} className="mb-0">
                          Certified CPR Responder
                        </h6>
                        <p className="mb-0">
                          How to give CPR to pregnant women
                        </p>
                        <img
                          src={Images.rating_img}
                          alt=""
                          className="img-fluid rating-icon-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row mx-0 card-common-row align-items-center card-common-row-small training-card">
                    <div className="d-flex incident-heading-inner position-relative">
                      <Button
                        className="more-info-btn bg-transparent p-0 border-0 shadow-none rounded-0 h-auto"
                        onClick={(e) => e.preventDefault()}
                      >
                        <img
                          style={{ width: "auto", height: "auto" }}
                          src={Images.heart_icon_gray}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                      <img
                        src={Images.docs_folder_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="training-details-div">
                        <h6 style={{ top: 0 }} className="mb-0">
                          Certified CPR Responder
                        </h6>
                        <p className="mb-0">
                          How to give CPR to pregnant women
                        </p>
                        <img
                          src={Images.rating_img}
                          alt=""
                          className="img-fluid rating-icon-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trainings;
