import React from 'react';

const BannerMain = (props) => {
    return (
        <>
            <div className="container-fluid main-content-fluid main-content-banner position-relative">
                <div className="container h-100">
                    <div className="row h-100 banner-heading align-items-center">
                        <div className="col-12 text-center">
                            <h1 className="mb-0">{props.headingName}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BannerMain;
