import React, { useState } from "react";
import { Button, Dropdown } from "antd";
import { Image as Images } from "../Images";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import { reverse } from "named-urls";

const CustomHeader = () => {
  const [clicked, setClicked] = useState("");
  const handleClick = () => {
    clicked ? setClicked("") : setClicked("base-state click-state");
  };
  const nav = useNavigate();
  return (
    <>
      <div
        className={`position-fixed sidebar-custom-main ${
          clicked ? "sidebar-active" : ""
        }`}
      >
        <div className="sidebar-custom-main-inner">
          <div className="row">
            <div className="col-12 position-relative">
              <Button
                onClick={handleClick}
                className="close-btn shadow-none position-absolute bg-transparent p-0 h-auto border-0"
              >
                <img src={Images.cross_icon} alt="" className="img-fluid" />
              </Button>
              <Link
                className="d-flex align-items-center logo-tag"
                to={ROUTES.auth.login}
              >
                <img src={Images.cpr_logo} alt="" className="img-fluid" />
                <span>CPR Ready</span>
              </Link>
              <ul className="list-inline mb-0 sidebar-list">
                <li>
                  <Link className="d-flex align-items-center" to={ROUTES.auth.login}>
                    <img
                      src={Images.report_an_incident_icon}
                      alt=""
                      className="img-fluid"
                    />
                    Report an Incident
                  </Link>
                </li>
                <li>
                  <Link
                    className="d-flex align-items-center"
                    to={ROUTES.dashboard.incident_dashboard}
                  >
                    <img
                      src={Images.incident_dashboard_icon}
                      alt=""
                      className="img-fluid"
                    />
                    Incident Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    className="d-flex align-items-center"
                    to={ROUTES.trainings.self}
                  >
                    <img
                      src={Images.training_sidebar_icon}
                      alt=""
                      className="img-fluid"
                    />
                    Training
                  </Link>
                </li>
                <li>
                  <Link className="d-flex align-items-center" to={ROUTES.auth.login}>
                    <img
                      src={Images.im_a_responder_icon}
                      alt=""
                      className="img-fluid"
                    />
                    I'm a Responder
                  </Link>
                </li>
                <li>
                  <Link
                    className="d-flex align-items-center"
                    to={ROUTES.support.self}
                  >
                    <img
                      src={Images.support_icon}
                      alt=""
                      className="img-fluid"
                    />
                    Support
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header-main position-fixed w-100">
        <Button
          onClick={handleClick}
          className="toggle-btn p-0 border-0 shadow-none rounded-0 bg-transparent h-auto"
        >
          <img src={Images.toggle_btn_icon} alt="" className="img-fluid" />
        </Button>
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-between">
            {/* <div className="toggle-with-back-arrow w-auto">
              <Button className="back-arrow-btn p-0 border-0 shadow-none rounded-0 bg-transparent h-auto">
                <img
                  src={Images.back_arrow_icon}
                  alt=""
                  className="img-fluid"
                />
                Back
              </Button>
            </div> */}
            <div className="logo-div w-auto">
              <Button className="p-0 border-0 shadow-none rounded-0 bg-transparent h-auto">
                <img src={Images.cpr_logo} alt="" className="img-fluid" />
                <span>CPR Ready</span>
              </Button>
            </div>
            <div className="header-dropdown-custom w-auto">
              <Button
                className="custom-dropdown p-0 border-0 shadow-none rounded-0 bg-transparent"
                onClick={(e) => {
                  nav(reverse(ROUTES.profile.self, { id: "sanchit" }));
                }}
              >
                <img src={Images.avatar} alt="" className="img-fluid" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomHeader;
