import React from "react";
import UserHeader from "../headers/UserHeader";
import UserSidebar from "../user-sidebar/UserSidebar";
import { useLocation, useParams } from "react-router-dom";
const AssinedIncidents = () => {
  return (
    <>
      <div className="main-user-content">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-xl-11 px-0">
            <div className="row">
              <div className="col-12 col-ms-12 col-md-6">
                <div className="row mx-0 assigned-incident-card">
                  <div className="col-12">
                    <h6>10, Max Ave,</h6>
                    <h6>Male - 60yrs, Person Unconscious,</h6>
                    <p className="mb-0">CPR Requested</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-ms-12 col-md-6">
                <div className="row mx-0 assigned-incident-card">
                  <div className="col-12">
                    <h6>10, Max Ave,</h6>
                    <h6>Male - 60yrs, Person Unconscious,</h6>
                    <p className="mb-0">CPR Requested</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-ms-12 col-md-6">
                <div className="row mx-0 assigned-incident-card">
                  <div className="col-12">
                    <h6>10, Max Ave,</h6>
                    <h6>Male - 60yrs, Person Unconscious,</h6>
                    <p className="mb-0">CPR Requested</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-ms-12 col-md-6">
                <div className="row mx-0 assigned-incident-card">
                  <div className="col-12">
                    <h6>10, Max Ave,</h6>
                    <h6>Male - 60yrs, Person Unconscious,</h6>
                    <p className="mb-0">CPR Requested</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-ms-12 col-md-6">
                <div className="row mx-0 assigned-incident-card">
                  <div className="col-12">
                    <h6>10, Max Ave,</h6>
                    <h6>Male - 60yrs, Person Unconscious,</h6>
                    <p className="mb-0">CPR Requested</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-ms-12 col-md-6">
                <div className="row mx-0 assigned-incident-card">
                  <div className="col-12">
                    <h6>10, Max Ave,</h6>
                    <h6>Male - 60yrs, Person Unconscious,</h6>
                    <p className="mb-0">CPR Requested</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssinedIncidents;
