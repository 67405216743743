import { reverse } from "named-urls";
import { ENDPOINT } from "../config/endpoint";
import { API_GATEWAY } from "./api_service";
import { ROUTES } from "../config/routes";

export const GetIncident = async (data) => {
  const URL = reverse(ENDPOINT.incident.get_incident.self);
  try {
    const response = await API_GATEWAY.Post(URL, data);
    return response;
  } catch (error) {}
};

export const GetSpecificIncindet = async (incidentId) => {
  const URL = reverse(ENDPOINT.incident.incident.id, { id: incidentId });
  try {
    const response = await API_GATEWAY.Get(URL, {});
    return response;
  } catch (error) {}
};

export const UpdateIncidentStatus = async (action, payload) => {
  let URL = "";
  if (action === 1) {
    URL = ENDPOINT.incident.accept_incident;
  } else {
    URL = ENDPOINT.incident.update_status;
  }
  try {
    const response = await API_GATEWAY.Post(URL, payload, {});
    return response;
  } catch (error) {}
};
