import React from "react";
import CustomHeader from "../headers/CustomHeader";
import CustomFooter from "../CustomFooter";
import { Image as Images } from "../Images";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";

const SelectTraining = () => {
  const nav = useNavigate();
  return (
    <>
      <div className="container-fluid main-inner-fluid auths-details-main">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div className="col-12 text-center">
              <Link to={ROUTES.auth.login}>
                <img
                  src={Images.logo_auths}
                  alt=""
                  className="img-fluid auths-logo mb-3"
                />
              </Link>
            </div>
            <div className="auths-main-card auths-main-card-training">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    src={Images.training_book_icon}
                    alt=""
                    className="img-fluid"
                  />
                  <h5>Training</h5>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <Button
                        className="text-uppercase"
                        onClick={() => nav(ROUTES.trainings.dashboard)}
                      >
                        AED
                      </Button>
                    </li>
                    <li className="list-inline-item">
                      <Button
                        className="text-uppercase primary-btn"
                        onClick={() => nav(ROUTES.trainings.dashboard)}
                      >
                        CPR
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTraining;
