import React from "react";
import CustomHeader from "../headers/CustomHeader";
import CustomFooter from "../CustomFooter";
import BannerMain from "../BannerMain";
import { Collapse, Tabs } from "antd";
import { Image as Images } from "../Images";

const { Panel } = Collapse;

const Support = () => {
  return (
    <>
      <CustomHeader />
      <BannerMain headingName={"Support"} />
      <div className="main-inner-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mx-auto">
              <Tabs
                className="custom-tab"
                type="card"
                items={["Pending Request", "Closed Request"].map((item, i) => {
                  const id = String(i + 1);
                  return {
                    label: item,
                    key: id,
                    children: (
                      <div className="custom-collapse-div">
                        <Collapse accordion>
                          <Panel
                            header={
                              <>
                                <div>Lorem ipsum dolor sit</div>
                                <div className="expand-icon-div">
                                  <img
                                    src={Images.plus_icon}
                                    alt=""
                                    className="img-fluid plus-icon"
                                  />
                                  <img
                                    src={Images.minus_icon}
                                    alt=""
                                    className="img-fluid minus-icon"
                                  />
                                </div>
                              </>
                            }
                            key="1"
                          >
                            <p>
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Aperiam possimus excepturi,
                              tenetur, est recusandae
                            </p>
                            <span>8:12 PM</span>
                          </Panel>
                          <Panel
                            header={
                              <>
                                <div>My message is no longer available</div>
                                <div className="expand-icon-div">
                                  <img
                                    src={Images.plus_icon}
                                    alt=""
                                    className="img-fluid plus-icon"
                                  />
                                  <img
                                    src={Images.minus_icon}
                                    alt=""
                                    className="img-fluid minus-icon"
                                  />
                                </div>
                              </>
                            }
                            key="2"
                          >
                            <p>
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Aperiam possimus excepturi,
                              tenetur, est recusandae
                            </p>
                            <span>8:12 PM</span>
                          </Panel>
                          <Panel
                            header={
                              <>
                                <div>This account content does not fol…</div>
                                <div className="expand-icon-div">
                                  <img
                                    src={Images.plus_icon}
                                    alt=""
                                    className="img-fluid plus-icon"
                                  />
                                  <img
                                    src={Images.minus_icon}
                                    alt=""
                                    className="img-fluid minus-icon"
                                  />
                                </div>
                              </>
                            }
                            key="3"
                          >
                            <p>
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Aperiam possimus excepturi,
                              tenetur, est recusandae
                            </p>
                            <span>8:12 PM</span>
                          </Panel>
                        </Collapse>
                      </div>
                    ),
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
