import { Image as Images } from "../components/Images";

export const DRAWER_DATA = [];

export const INCIDENT_DASHBOARD_BUTTONS = [
  {
    action: "Incident Location",
    icon: Images.incident_location_icon_primary,
  },
  {
    action: "Responder Acceptance",
    icon: Images.responder_acceptance,
  },
  {
    action: "Navigation",
    icon: Images.navigation,
  },
  {
    action: "Responder Details",
    icon: Images.responder_details,
  },
  {
    action: "Responder attending patient",
    icon: Images.responder_attending_patient,
  },
];
