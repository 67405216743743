import React from "react";
import UserSidebar from "../user-sidebar/UserSidebar";
import UserHeader from "../headers/UserHeader";
import { Switch } from "antd";

const Preferences = () => {
  return (
    <>
      <div className="main-user-content">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-xl-11 px-0">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-6">
                <ul className="mb-0 list-inline preferences-ul">
                  <li className="position-relative">
                    <span>Default Region</span>
                    <div className="switch-btn-div position-absolute">
                      <Switch defaultChecked />
                    </div>
                  </li>
                  <li className="position-relative">
                    <span>Allow Push Notifications when in DND</span>
                    <div className="switch-btn-div position-absolute">
                      <Switch defaultChecked />
                    </div>
                  </li>
                  <li className="position-relative">
                    <span>Allow Public to view Profile</span>
                    <div className="switch-btn-div position-absolute">
                      <Switch defaultChecked />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
