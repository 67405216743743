import React from "react";
import UserHeader from "../headers/UserHeader";
import { Button, Form, Input, Select } from "antd";
import { Image as Images } from "../Images";
const UserProfile = () => {
  return (
    <>
      <div className="main-user-content">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-xl-11">
            <Form
              className="common-form-main"
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              // initialValues={{
              //     remember: true,
              // }}
              // onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    label={false}
                    name="name"
                    rules={[
                      {
                        required: false,
                        message: "Please Input Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    label={false}
                    name="l_name"
                    rules={[
                      {
                        required: false,
                        message: "Please Input last name!",
                      },
                    ]}
                  >
                    <Input placeholder="last name" />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    label={false}
                    name="Age"
                    rules={[
                      {
                        required: false,
                        message: "Please Select Age!",
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={
                        <img
                          src={Images.caret_icon_down}
                          alt=""
                          className="img-fluid"
                        />
                      }
                      defaultValue="Age"
                      options={[
                        {
                          value: "Age",
                          label: "Age",
                        },
                        {
                          value: "Age",
                          label: "Age",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    label={false}
                    name="Gender"
                    rules={[
                      {
                        required: false,
                        message: "Please Select Gender!",
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={
                        <img
                          src={Images.caret_icon_down}
                          alt=""
                          className="img-fluid"
                        />
                      }
                      defaultValue="Gender"
                      options={[
                        {
                          value: "male",
                          label: "Male",
                        },
                        {
                          value: "female",
                          label: "Female",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    label={false}
                    name="Medical_history"
                    rules={[
                      {
                        required: false,
                        message: "Please Input Medical history!",
                      },
                    ]}
                  >
                    <Input placeholder="Medical history" />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    label={false}
                    name="Address"
                    rules={[
                      {
                        required: false,
                        message: "Please Input Address!",
                      },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Button className="go-back-dashboard-btn mt-2 w-100">
                    Update Profile
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
