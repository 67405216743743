import { toast } from "react-toastify";
import { axiosInstance } from "./api_interceptor";
import { toastConfig } from "../config/config";

export class API_GATEWAY {
  static Get = async (URL, params) => {
    return await axiosInstance
      .get(URL, {
        params: params,
      })
      .then((data) => {
        if (data.status >= 200 && data.status < 300) {
          toast.success(
            data.data?.message ? data.data?.message : data.data?.success,
            toastConfig
          );
          return data.data;
        }
      })
      .catch((error) => {
        return error?.response?.data;
      });
  };
  static Post = async (URL, payload, params) => {
    return await axiosInstance
      .post(URL, payload, { params: params })
      .then((data) => {
        if (data.status >= 200 && data.status < 300) {
          toast.success(
            data.data?.message ? data.data?.message : data.data?.success,
            toastConfig
          );
          return data.data;
        }
      })
      .catch((error) => {
        return error?.response?.data;
      });
  };

  static Put = async (URL, payload, params) => {
    return await axiosInstance
      .put(URL, payload, { params: params })
      .then((data) => data)
      .catch((error) => {
        return error?.response?.data;
      });
  };

  static Patch = async (URL, payload, params) => {
    return await axiosInstance
      .patch(URL, payload, { params: params })
      .then((data) => data)
      .catch((error) => {
        return error?.response?.data;
      });
  };

  static Delete = async (URL) => {
    return await axiosInstance
      .delete(URL)
      .then((data) => data)
      .catch((error) => {
        return error?.response?.data;
      });
  };
}
