import React from "react";
import { Image as Images } from "../Images";
import PopoverMenu from "./PopoverMenu";
import { CarOutlined, HomeOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { generateStatus, statusText } from "../../config/config";

const IncidentCard = ({ incidentData }) => {
  return (
    <div className="row mx-0 card-common-row card-common-row-small">
      <div className="col-12">
        <div className="d-flex incident-heading-inner">
          <h6>Incident: #{incidentData.incident_id}</h6>
        </div>
        <p className="hide-text mt-3">
          <HomeOutlined />
          &nbsp;
          <Tooltip arrow={false} title={incidentData.region}>
            {incidentData.region}
          </Tooltip>
        </p>
        <p>
          <CarOutlined />
          &nbsp;
          {(Math.round(incidentData.radius * 100) / 100).toFixed(2)} Miles
        </p>
        <div className="d-flex align-items-center">
          {generateStatus(incidentData.status)}
          {statusText(incidentData.status)}
        </div>
      </div>
    </div>
  );
};

export default IncidentCard;
