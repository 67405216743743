import React, { useState } from "react";
import { Image as Images } from "../../components/Images";
import { Button, Form, Input } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/routes";
import OTPInput from "react-otp-input";
import { VerifyOTP } from "../../network/authentication.network";

const VerificationCode = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  const verifyOTP = async () => {
    setLoader(true);
    const payload = {
      secret: location?.state?.secret_code,
      otp: otp,
      lat: 0,
      long: 0,
    };
    const response = await VerifyOTP(payload);
    if (response) {
      navigate(ROUTES.dashboard.incident_dashboard);
    }
    setLoader(false);
  };
  return (
    <>
      <div className="container-fluid main-inner-fluid auths-details-main">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div
              className="auths-main-card"
              style={{
                width: "fit-content",
              }}
            >
              <div className="row">
                <div className="col-12 text-center">
                  <h1>Verification Code</h1>
                  <p>
                    Enter the code we have sent you on
                    <br />
                    your Mobile No.
                    <br />
                    {location?.state?.phone_number}
                  </p>
                  <Form onFinish={verifyOTP} autoComplete="off">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <OTPInput
                          onChange={setOtp}
                          value={otp}
                          numInputs={6}
                          inputStyle={{ width: "80%" }}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => (
                            <div className="country-code-div">
                              <Input
                                style={{ textAlign: "center", width: "80%" }}
                                {...props}
                              />
                            </div>
                          )}
                        />
                      </div>
                      <h6 className="text-center resend-code">
                        <Link to={ROUTES.auth.login}>Resend Code</Link>
                      </h6>
                    </Form.Item>

                    <Form.Item className="mb-0">
                      <Button
                        loading={loader}
                        htmlType="submit"
                        type="primary"
                        className="text-uppercase"
                      >
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                  {/* <div className="row">
                    <div className="col-12 continue-with-div text-center position-relative">
                      <h6>
                        <span>or continue with</span>
                      </h6>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <Button>
                            <img
                              src={Images.apple_logo}
                              alt=""
                              className="img-fluid"
                            />
                          </Button>
                        </li>
                        <li className="list-inline-item">
                          <Button>
                            <img
                              src={Images.facebook_logo}
                              alt=""
                              className="img-fluid"
                            />
                          </Button>
                        </li>
                        <li className="list-inline-item">
                          <Button>
                            <img
                              src={Images.google_logo}
                              alt=""
                              className="img-fluid"
                            />
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
